import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

export default function Loading(props) {
  return (
    <section
      id="l-loading"
      className="h-center h-animation-config"
      data-display={props.display}
    >
      <div id="l-loading__spinner" className="h-animation-config">
        <div id="c-loading__circle"></div>
      </div>
      <h2 id="c-loading__title">Welcome</h2>
    </section>
  );
}

Loading.propTypes = {
  display: PropTypes.string.isRequired,
};
