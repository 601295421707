import React, { useState, useRef, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { BrowserRouter as Router } from "react-router-dom";
import "./style.scss";

import AppContext from "contexts/AppContext";
import firebaseConfig from "services/Firebase";

import Loading from "./components/Loading";
import Frame from "./components/Frame";
import Routes from "./components/Routes";

export default function App() {
  const outside = useRef();
  const [database, setDatabase] = useState();
  const [loadingState, setLoadingState] = useState(true);

  useEffect(() => {
    initializeApp(firebaseConfig);
    const db = getDatabase();
    const valueRef = ref(db, "/");
    onValue(valueRef, (snapshot) => {
      setDatabase(snapshot.val());
      setLoadingState(false);
    });
  }, []);

  const contextValue = {
    outside,
    database,
  };

  return (
    <AppContext.Provider value={contextValue}>
      <section
        id="l-app"
        className="l-flex--row-nowrap l-flex--center-center"
        ref={outside}
      >
        {database && (
          <Router>
            <Routes />
            <Frame />
          </Router>
        )}
        <Loading display={loadingState ? "show" : "hide"} />
      </section>
    </AppContext.Provider>
  );
}
