import React from 'react';
import styled from 'styled-components';

const StyledStar = styled.div`
    position: relative;
    height: 1px;
    width: 1px;
`

const Circle = styled.div`
    position: absolute;
    top: -50%;
    left: -50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;

    height: ${props => `${props.size}px` || "50px"};
    width: ${props => `${props.size}px` || "50px"};
    background: ${props => props.background || "50px"};
    opacity: ${props => props.opacity || "50px"};
`

export default function Star({
    background = "#2A002A",
    layers = [{
        size: 50,
        opacity: 1,
    }],
}) {
    return (
        <StyledStar>
            {layers.map((layer, index) => {
                return (
                    <Circle
                        key={index}
                        size={layer.size}
                        opacity={layer.opacity}
                        background={background}
                    />
                )
            })}
        </StyledStar>
    )
}