/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

import Button from "components/Button";

export default function NotFound() {
  return (
    <section id="l-not-found">
      <img id="c-not-found__astronout" src="/media/icons/lost-astronout.svg" />
      <img id="c-not-found__sun" src="/media/icons/sun.svg" />
      <div id="l-not-found__description">
        <div id="l-not-found__error" className="l-flex--row-nowrap">
          <h1 id="c-not-found__error-code">404</h1>
          <h3 id="c-not-found__error-suffix">Error</h3>
        </div>
        <div id="l-not-found__detail">
          <h4 id="c-not-found__message">Whooah, you lost!</h4>
          <p id="c-not-found__clue">
            Don't worry, you can go home by clicking the button below
          </p>
        </div>
        <div id="c-not-found__home-button">
          <Link to="/">
            <Button name="home" do={() => {}} />
          </Link>
        </div>
      </div>
    </section>
  );
}
