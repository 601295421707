import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "./style.scss";

import useWindowDimensions from "utils/Util";
import Planet from "components/Planet";
import Tag from "components/Tag";
import { default as DateComponent } from "components/Date";

export default function Experience(props) {
  const { width } = useWindowDimensions();
  const [currentDateTop, setCurrentDateTop] = useState(0);
  const [currentDate, setCurrentDate] = useState(props.beginDate);
  const [detailHeight, setDetailHeight] = useState(0);
  const [dateHeight, setDateHeight] = useState(0);
  const [tagScale, setTagScale] = useState(1);
  const detailRef = useRef();
  const currentDateRef = useRef();

  const currentDateStyle = {
    transform: `translate(0px, ${currentDateTop}px)`,
  };

  const moveCurrentDate = (e) => {
    const container = detailRef.current.getBoundingClientRect();
    let y = e.clientY - container.top;
    if (y < 0) {
      y = 0;
    } else if (y > detailHeight) {
      y = detailHeight;
    }

    const currentDate = new Date(
      new Date(props.beginDate).getTime() +
        (new Date(props.endDate).getTime() -
          new Date(props.beginDate).getTime()) *
          (y / detailHeight)
    );

    setCurrentDate(currentDate.toISOString().split("T")[0]);
    setCurrentDateTop(y - dateHeight / 2);
  };

  useEffect(() => {
    if (detailRef && currentDateRef) {
      setDetailHeight(detailRef.current.clientHeight);
      setDateHeight(currentDateRef.current.clientHeight);
    }
  }, [detailRef, currentDateRef]);

  useEffect(() => {
    if (width < 400) {
      setTagScale(0.8);
    } else if (width < 700) {
      setTagScale(0.85);
    } else {
      setTagScale(1);
    }
  }, [width]);

  return (
    <div className="l-experience" onMouseMove={(e) => moveCurrentDate(e)}>
      <h3 className="c-experience__date l-flex--row-nowrap" data-id="begin">
        <DateComponent value={props.beginDate} fontSize={12} />
      </h3>
      <div className="l-experience__title-circle l-flex--row-nowrap l-flex--center-center">
        <div className="c-experience__empty-circle"></div>
        <div className="c-experience__planet h-animation-config">
          <Planet size={15} color={"white"} />
        </div>
      </div>
      <h2 className="c-experience__role l-flex--row-nowrap">{props.role}</h2>
      <div
        className="l-experience__date l-flex--column-nowrap"
        data-id="current"
      >
        <div
          className="c-experience__date h-animation-config"
          style={currentDateStyle}
          ref={currentDateRef}
        >
          <DateComponent value={currentDate} fontSize={12} />
        </div>
      </div>
      <div className="l-experience__dotted-line l-flex--row-nowrap l-flex--center-center">
        <div className="c-experience__dotted-line"></div>
      </div>
      <div className="l-experience__detail" ref={detailRef}>
        <h2 className="c-experience__company">{props.company}</h2>
        <h4 className="c-experience__range-date">
          {new Date(props.beginDate).toLocaleString().split(",")[0]} -{" "}
          {new Date(props.endDate).toLocaleString().split(",")[0]}
        </h4>
        <p
          className="c-experience__brief"
          dangerouslySetInnerHTML={{ __html: props.detail }}
        />
        <div className="l-experience__tags">
          {props.tags.map((tagName, index) => {
            return (
              <div key={index} className="c-experience__tag">
                <Tag
                  name={tagName}
                  fontSize={11}
                  scale={tagScale}
                  isOutline="false"
                />
              </div>
            );
          })}
        </div>
      </div>
      <h3 className="c-experience__date l-flex--row-nowrap" data-id="end">
        <DateComponent value={props.endDate} fontSize={12} />
      </h3>
      <div className="l-experience__cross l-flex--row-nowrap l-flex--center-center">
        <div className="c-experience__cross"></div>
      </div>
      {/* <h2 className="c-experience__finish l-flex--row-nowrap"></h2> */}
    </div>
  );
}

Experience.propTypes = {
  beginDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
};
