import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import {
    AboutSection,
    ExperienceSection,
    ProjectSection,
    NotFound,
    EditorSection,
} from 'scenes';
import Background from './components/Background';

const ENDPOINT_MAP_TO_BACKGROUND_STATE = {
    '/home': 0,
    '/experiences': 1,
    '/projects': 2,
}

export default function Routes() {
    const history = useHistory();

    return (
        <>
            <Background
                padding={400}
                maxState={2}
                state={ENDPOINT_MAP_TO_BACKGROUND_STATE[history.location.pathname]}
            />
            <Switch>
                <Route exact path='/' component={AboutSection} />
                <Route path='/home' component={AboutSection} />
                <Route path='/experiences/:id' component={(ExperienceSection)} />
                <Route path='/experiences' component={ExperienceSection} />
                <Route path='/projects' component={ProjectSection} />
                <Route path='/editor' component={EditorSection} />
                <Route component={NotFound} />
            </Switch>
        </>
    )
}