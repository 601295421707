/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import "./style.scss";

import AppContext from "contexts/AppContext";
import Title from "components/Title";
import Experience from "./components/Experience";

export default function ExperienceSection({ history, match }) {
  const {
    params: { id },
  } = match;

  const isMobile = useMediaQuery({
    maxWidth: 700,
  });

  const {
    database: { experiences },
  } = useContext(AppContext);

  if (id < 0 || id >= experiences.length) history.push("/experiences/0");
  const [index, setIndex] = useState(id ? parseInt(id) : 0);

  const experiencesView = experiences.map((experience, index) => {
    return (
      <div key={index} className="l-experience-section__experience">
        <Experience
          id={experience.id}
          beginDate={experience.beginDate}
          endDate={experience.endDate}
          role={experience.role}
          company={experience.company}
          detail={experience.detail}
          tags={experience.tags}
        />
      </div>
    );
  });

  const changePage = (isNextPage) => {
    let newIndex;
    if (isNextPage) {
      newIndex = index + 1;
    } else {
      newIndex = index - 1;
    }

    if (0 <= newIndex && newIndex < experiences.length) {
      setIndex(newIndex);
    } else if (0 > newIndex) {
      setIndex(experiences.length - 1);
    } else {
      setIndex(0);
    }
  };

  let prevIndex = index - 1;
  let nextIndex = index + 1;

  if (prevIndex < 0) prevIndex = experiences.length - 1;
  if (nextIndex >= experiences.length) nextIndex = 0;

  return (
    <section id="l-experience-section">
      {isMobile && (
        <div id="l-experience-section__navigator">
          <Link
            id="c-navigator__left"
            className="c-navigator__arrow h-center h-animation-config"
            to={`/experiences/${prevIndex}`}
            onClick={() => changePage(false)}
          >
            <img src="/media/icons/arrow.svg" />
          </Link>
          <Link
            id="c-navigator__right"
            className="c-navigator__arrow h-center h-animation-config"
            to={`/experiences/${nextIndex}`}
            onClick={() => changePage(true)}
          >
            <img src="/media/icons/arrow.svg" />
          </Link>
        </div>
      )}
      <div id="l-experience-section__title">
        <Title name="Experiences" iconUrl="/media/icons/briefcase.svg" />
      </div>
      <div id="l-experience-section__experiences">
        {isMobile ? experiencesView[index] : experiencesView}
      </div>
    </section>
  );
}
