import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const ORBITER_SIZE_FACTOR = 0.3;

export default function Planet(props) {
  const planetStyle = {
    height: props.size,
    width: props.size,
    backgroundColor: props.color,
  };

  const orbiterStyle = {
    height: props.size * ORBITER_SIZE_FACTOR,
    width: props.size * ORBITER_SIZE_FACTOR,
    backgroundColor: props.color,
  };

  return (
    <div className="c-planet" style={planetStyle}>
      <div className="c-planet__orbiter" style={orbiterStyle}></div>
    </div>
  );
}

Planet.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
