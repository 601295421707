import React from 'react';
import PropTypes from "prop-types";
import './style.scss';

export default function ContactMedia(props) {
    return(
        <a className='l-contact-media' href={props.href} target='_blank' rel="noopener noreferrer">
            <div className='c-contact-media__circle-art h-animation-config'></div>
            <img className='c-contact-media__icon h-animation-config' src={props.iconUrl} alt='Contact Icon' />
        </a>
    );
}

ContactMedia.propTypes = {
    href: PropTypes.string.isRequired,
    iconUrl: PropTypes.string,
}