import React, { useContext, useState, useEffect } from "react";
import "./style.scss";

import useWindowDimensions from "utils/Util";
import AppContext from "contexts/AppContext";
import Text from "components/Text";

import SummaryPagination from "./components/SummaryPagination";
import SummaryTagSkill from "./components/SummaryTagSkill";
import useBooks from "./hooks/useBooks";

const SUMMARY_FONT_SIZE = 55;
const SUMMARY_LIST = [
  ["Hi,", "I'm Nandhika Prayoga"],
  ["Working in website development", "with 3+ years of experience"],
  ["Designing interactive", "UI and animation"],
  ["Specialized in", "Full-stack Engineering"],
  ["Strong dedication", "to achieve anything"],
  ["Great at planning,", "Focus on the goal"],
  ["Building trust,", "Huge commitment"],
];

export default function AboutSection() {
  const {
    database: { skills },
  } = useContext(AppContext);
  const { height, width } = useWindowDimensions();
  const [summaryFontSize, setSummaryFontSize] = useState(SUMMARY_FONT_SIZE);
  const [summary, goNextPage, , selectPage] = useBooks(SUMMARY_LIST);

  useEffect(() => {
    const summaryTimer = setInterval(() => {
      goNextPage();
    }, 5000);
    return () => clearInterval(summaryTimer);
  }, [goNextPage]);

  useEffect(() => {
    if (width < 700) {
      setSummaryFontSize(24);
    } else {
      setSummaryFontSize(SUMMARY_FONT_SIZE);
    }
  }, [height, width]);

  return (
    <section
      id="l-about-section"
      className="l-flex--column-nowrap l-flex--center-center"
    >
      <div id="l-about-section__summary">
        <Text
          value={summary.page}
          fontSize={summaryFontSize}
          fontWeight={"bold"}
        />
        <SummaryPagination
          page={summary.pageNumber}
          setPage={(page) => selectPage(page)}
          maxPage={SUMMARY_LIST.length}
        />
      </div>
      <div id="l-about-section__skills">
        {skills.map((skill, index) => {
          return (
            <div key={index} className="l-about-section__skill">
              <SummaryTagSkill
                name={skill.name}
                percentage={skill.percentage}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
}
