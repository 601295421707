import { useReducer } from "react";

const BOOKS_ACTIONS = {
  NEXT: 1,
  PREV: 2,
  SELECT: 3,
};

function setPageNumber(books, pageNumber) {
  if (pageNumber >= books.length) {
    return 0;
  } else if (pageNumber < 0) {
    return books.length - 1;
  } else {
    return pageNumber;
  }
}

function booksHandler(summary, action) {
  switch (action.type) {
    case BOOKS_ACTIONS.NEXT:
      return booksHandler(summary, {
        type: BOOKS_ACTIONS.SELECT,
        pageNumber: summary.pageNumber + 1,
      });
    case BOOKS_ACTIONS.PREV:
      return booksHandler(summary, {
        type: BOOKS_ACTIONS.SELECT,
        pageNumber: summary.pageNumber - 1,
      });
    case BOOKS_ACTIONS.SELECT:
      const newPageNumber = setPageNumber(summary.list, action.pageNumber);
      return {
        ...summary,
        pageNumber: newPageNumber,
        page: summary.list[newPageNumber],
      };
    default:
      throw new Error(`There is no such ${action.type} in action list`);
  }
}

export default function useBooks(books) {
  const [booksState, booksDispatch] = useReducer(booksHandler, {
    list: books,
    pageNumber: 0,
    page: books[0],
  });

  const goNext = () => {
    booksDispatch({
      type: BOOKS_ACTIONS.NEXT,
    });
  };

  const goPrev = () => {
    booksDispatch({
      type: BOOKS_ACTIONS.PREV,
    });
  };

  const setManualPageNumber = (pageNumber) => {
    booksDispatch({
      type: BOOKS_ACTIONS.SELECT,
      pageNumber: pageNumber,
    });
  };

  const publicBooksState = {
    page: booksState.page,
    pageNumber: booksState.pageNumber,
  };

  return [publicBooksState, goNext, goPrev, setManualPageNumber];
}
