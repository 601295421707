import React, { useState, useEffect, useRef, useContext } from "react";
import "./style.scss";

import AppContext from "contexts/AppContext";
import PageLink from "./components/PageLink";

export default function Menu() {
  const [menuState, setMenuState] = useState("idle");
  const [menuIsClicked, setClickState] = useState(false);
  const body = useRef();
  const { outside } = useContext(AppContext);

  const handleMenuButtonOnHover = (enter) => {
    if (!menuIsClicked) {
      if (enter) {
        setMenuState("solar-system");
      } else {
        setMenuState("idle");
      }
    }
  };

  const handleMenuButtonOnClick = () => {
    if (menuIsClicked) {
      setClickState(false);
      setMenuState("idle");
    } else {
      setClickState(true);
      setMenuState("star-system");
    }
  };

  const closeMenu = () => {
    setClickState(false);
    setMenuState("idle");
  };

  useEffect(() => {
    const handleCloseMenu = (e) => {
      if (
        outside.current.contains(e.target) &&
        !body.current.contains(e.target)
      ) {
        setClickState(false);
        setMenuState("idle");
      }
    };
    document.addEventListener("mousedown", handleCloseMenu);
    return () => {
      document.removeEventListener("mousedown", handleCloseMenu);
    };
  });

  return (
    <div id="l-menu" data-state={menuState}>
      <div
        id="l-menu__button"
        onMouseEnter={() => handleMenuButtonOnHover(true)}
        onMouseLeave={() => handleMenuButtonOnHover(false)}
        onClick={() => handleMenuButtonOnClick()}
      >
        <div id="h-rotating-planet--2" className="h-rotating-planet">
          <div className="c-button__circle-art" data-position="2"></div>
          <div className="c-button__line-art" data-position="3"></div>
        </div>
        <div className="c-button__line-art" data-position="2"></div>
        <div id="h-rotating-planet--1" className="h-rotating-planet">
          <div className="c-button__circle-art" data-position="1"></div>
          <div className="c-button__line-art" data-position="1"></div>
        </div>
      </div>
      <div id="l-menu__content">
        <div
          className="c-menu__star-layer h-animation-config"
          data-position="5"
        ></div>
        <div
          className="c-menu__star-layer h-animation-config"
          data-position="4"
        ></div>
        <div
          className="c-menu__star-layer h-animation-config"
          data-position="3"
        ></div>
        <div
          className="c-menu__star-layer h-animation-config"
          data-position="2"
        ></div>
        <div
          id="l-menu__body"
          className="c-menu__star-layer h-animation-config"
          data-position="1"
          ref={body}
        >
          <div id="l-menu__nav-list" className="l-flex--column-nowrap">
            <div className="l-menu__link l-flex--row-nowrap">
              <PageLink name="Home" href="/" callback={() => closeMenu()} />
            </div>
            <div className="l-menu__link l-flex--row-nowrap">
              <PageLink
                name="Experiences"
                href="/experiences"
                callback={() => closeMenu()}
              />
            </div>
            <div className="l-menu__link l-flex--row-nowrap">
              <PageLink
                name="Projects"
                href="/projects"
                callback={() => closeMenu()}
              />
            </div>
            <div className="l-menu__link l-flex--row-nowrap">
              <PageLink name="Labs" href="/labs" callback={() => closeMenu()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
