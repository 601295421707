/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./style.scss";

import Button from "components/Button";
import IconButton from "components/IconButton";
import Tag from "components/Tag";
import Number from "components/Number";

export default function ProjectModal(props) {
  const project = props.projects[props.projectIndex];

  const title = project.name;
  const imageUrl = project.imageUrl;
  const beginDate = new Date(project.beginDate);
  const endDate = project.endDate ? new Date(project.endDate) : new Date();
  const date = `${beginDate.toLocaleString("default", {
    month: "short",
  })} ${beginDate.getFullYear()} - ${endDate.toLocaleString("default", {
    month: "short",
  })} ${endDate.getFullYear()}`;
  const description = project.description;
  const sourceCodeUrl = project.sourceCodeUrl;
  const link = project.link;
  const tags = project.tags.map((tagName, index) => {
    return (
      <div className="c-project-modal__tag" key={index}>
        <Tag key={index} name={tagName} isOutline={true} />
      </div>
    );
  });

  const handleNextProject = (index) => {
    if (index < 0) {
      index = props.projects.length - 1;
    } else if (index >= props.projects.length) {
      index = 0;
    }
    props.setProjectIndex(index);
  };

  return (
    // Please fix this fucking display state
    <div
      id="l-project-modal"
      className="h-center h-animation-config"
      data-display={props.display ? "appear" : "hide"}
    >
      <div id="c-project-modal__wall" onClick={() => props.hideModal()} />
      <div id="l-project-modal__content">
        <div id="l-project-modal__thumbnail">
          <img id="c-project-modal__thumbnail" src={imageUrl} />
        </div>
        <div id="l-project-modal__pagination">
          <div id="c-project-modal__pagination" className="h-center">
            <button onClick={() => handleNextProject(props.projectIndex - 1)}>
              <img
                src="/media/icons/arrow.svg"
                className="c-project-modal__pagination-button"
                data-direction="left"
              />
            </button>
            <div className="h-center">
              <Number
                value={props.projectIndex + 1}
                digitLength={1}
                fontSize={14}
                fontWeight={"normal"}
              />
              <h3>
                /<strong>{props.projects.length}</strong>
              </h3>
            </div>
            <button onClick={() => handleNextProject(props.projectIndex + 1)}>
              <img
                src="/media/icons/arrow.svg"
                className="c-project-modal__pagination-button"
                data-direction="right"
              />
            </button>
          </div>
        </div>
        <div id="l-project-modal__detail">
          <div id="c-project-modal__detail">
            <h1 id="c-project-modal__title">{title}</h1>
            <h2 id="c-project-modal__date">{date}</h2>
            <p
              id="c-project-modal__description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div id="l-project-modal__tags">{tags}</div>
          </div>
          <div id="c-project-modal__buttons" className="h-center">
            {link ? (
              <Button
                name="visit"
                iconUrl="/media/icons/link.svg"
                do={() => window.open(link)}
                isDark={true}
              />
            ) : (
              <span />
            )}
            {link ? <span data-type="padding" /> : <span />}
            {sourceCodeUrl ? (
              <IconButton
                iconUrl="/media/icons/code.svg"
                do={() => window.open(sourceCodeUrl)}
                isDark={true}
              />
            ) : (
              <span />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
