import React, { useContext } from "react";
import "./style.scss";

import AppContext from "contexts/AppContext";
import ContactMedia from "./components/ContactMedia";

export default function Footer() {
  const {
    database: { contacts },
  } = useContext(AppContext);

  return (
    <footer id="l-footer">
      <div id="l-footer__contact-media" className="l-flex--row-nowrap">
        {contacts.map((contact, index) => {
          return (
            <ContactMedia
              key={index}
              href={contact.href}
              iconUrl={contact.iconUrl}
            />
          );
        })}
      </div>
    </footer>
  );
}
