import React, { useContext, useState } from "react";
import "./style.scss";

import AppContext from "contexts/AppContext";
import Title from "components/Title";
import Project from "./components/Project";
import ProjectModal from "./components/ProjectModal";

export default function ProjectSection() {
  const {
    database: { projects },
  } = useContext(AppContext);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [modalDisplay, setModalDisplay] = useState(false);

  const showModal = (projectIndex) => {
    setCurrentProjectIndex(projectIndex);
    setModalDisplay(true);
  };

  return (
    <section id="l-project-section">
      <div id="l-project-section__title">
        <Title name="Projects" iconUrl="/media/icons/lightbulb.svg" />
      </div>
      <div id="l-project-section__projects">
        {projects.map((project, index) => {
          return (
            <button
              key={index}
              className="l-project-section__project"
              onClick={() => showModal(index)}
            >
              <Project
                imageUrl={project.imageUrl}
                name={project.name}
                description={project.description}
                beginDate={project.beginDate}
                endDate={project.endDate}
                link={project.link}
                sourceUrl={project.sourceCodeUrl}
              />
            </button>
          );
        })}
      </div>
      <ProjectModal
        projects={projects}
        projectIndex={currentProjectIndex}
        setProjectIndex={(index) => setCurrentProjectIndex(index)}
        display={modalDisplay}
        hideModal={() => setModalDisplay(false)}
      />
    </section>
  );
}
