import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

export default function IconButton(props) {
  let backgroundColor;
  let iconFilter;
  if (props.isDark) {
    backgroundColor = "#2a002a";
    iconFilter = "brightness(0) invert(1)";
  } else {
    backgroundColor = "white";
    iconFilter = "none";
  }

  return (
    <button
      className="l-icon-button h-center"
      onClick={props.do}
      style={{
        backgroundColor,
      }}
    >
      <div className="c-icon-button__filler h-animation-config" />
      <img
        className="c-icon-button__icon"
        src={props.iconUrl}
        alt="Icon button"
        style={{
          filter: iconFilter,
        }}
      />
    </button>
  );
}

IconButton.propTypes = {
  iconUrl: PropTypes.string.isRequired,
  isDark: PropTypes.bool,
};
