/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

export default function Button(props) {
  let icon;
  if (props.iconUrl) {
    icon = <img className="c-button__icon" src={props.iconUrl} />;
  } else {
    icon = <div></div>;
  }

  let backgroundColor;
  let textColor;
  if (props.isDark) {
    backgroundColor = "#2A002A";
    textColor = "white";
  } else {
    backgroundColor = "white";
    textColor = "#2A002A";
  }

  return (
    <button
      className="l-button"
      onClick={props.do}
      style={{
        backgroundColor,
      }}
    >
      <div className="c-button__filler h-animation-config"></div>
      <div className="l-button__content h-center">
        <div className="l-button__name h-center h-animation-config">
          {icon}
          <h3
            className="c-button__name"
            style={{
              color: textColor,
            }}
          >
            {props.name}
          </h3>
        </div>
        <h3 className="c-button__go l-flex--row-nowrap l-flex--center-center h-animation-config">
          go
        </h3>
      </div>
    </button>
  );
}

Button.propTypes = {
  name: PropTypes.string.isRequired,
  iconUrl: PropTypes.string,
  do: PropTypes.func.isRequired,
};
