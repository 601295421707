import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

import Number from "../Number";
import Month from "./components/Month";

export default function Date(props) {
  const [year, month, day] = props.value
    .split("-")
    .map((value) => parseInt(value.replace(/ /g, "")));

  return (
    <span className="l-date l-flex--row-nowrap l-flex--center-center">
      <span className="l-date__day">
        <Number
          fontSize={props.fontSize}
          fontWeight={"normal"}
          digitLength={2}
          value={day}
        />
      </span>
      <span className="l-date__month">
        <Month fontSize={props.fontSize} fontWeight={"bold"} value={month} />
      </span>
      <span className="l-date__year">
        <Number
          fontSize={props.fontSize}
          fontWeight={"bold"}
          digitLength={4}
          value={year}
        />
      </span>
    </span>
  );
}

Date.propTypes = {
  value: PropTypes.string.isRequired,
  fontSize: PropTypes.number.isRequired,
};
