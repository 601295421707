import React, { useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import "./style.scss";

const MONTH = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function Month(props) {
  const [monthHeight, setMonthHeight] = useState();

  const monthRef = useRef();
  const positionStyle = {
    transform: `translate(0, -${(props.value - 1) * monthHeight}px)`,
  };

  const monthStyle = {
    fontSize: props.fontSize,
    height: props.fontSize,
  };

  useLayoutEffect(() => {
    if (monthRef.current) {
      const styles = window.getComputedStyle(monthRef.current);
      setMonthHeight(parseFloat(styles.height));
    }
  }, []);

  return (
    <div className="l-month l-flex--row-nowrap l-flex--center-center">
      <h2 className="h-month" style={monthStyle} ref={monthRef}>
        {MONTH[4]}
      </h2>
      <div className="l-month__list h-animation-config" style={positionStyle}>
        {MONTH.map((value, index) => {
          return (
            <h2 className="c-month" style={monthStyle} key={index}>
              {value}
            </h2>
          );
        })}
      </div>
    </div>
  );
}

Month.propTypes = {
  value: PropTypes.number.isRequired,
  fontSize: PropTypes.number.isRequired,
};
