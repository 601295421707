import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

export default function SummaryPagination(props) {
  return (
    <div id="l-summary-pagination" className="l-flex--row-nowrap">
      {Array.from(Array(props.maxPage), (value, index) => {
        let barState = "not-selected";
        if (index === props.page) {
          barState = "selected";
        }
        return (
          <button
            key={index}
            className="l-summary-pagination__bar"
            onClick={() => props.setPage(index)}
          >
            <div
              className="c-summary-pagination__bar h-animation-config"
              data-state={barState}
            ></div>
          </button>
        );
      })}
    </div>
  );
}

SummaryPagination.propTypes = {
  page: PropTypes.number.isRequired,
  maxPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};
