import React from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

import Planet from 'components/Planet';
import './style.scss';

export default function PageLink(props) {
    const callback = props.callback
        ? props.callback
        : () => {};

    return(
        <Link className='l-page-link' to={props.href} onClick={callback}>
            <h1 className='h-color--blue-dark h-animation-config'>
                { props.name }
            </h1>
            <div className='l-page-link__planet h-animation-config'>
                <Planet size={20} color={'#2a002a'} />
            </div>
        </Link>
    );
}

PageLink.propTypes = {
    name: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    callback: PropTypes.func,
}