import React, { useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";

export default function Digit(props) {
  const [digitHeight, setDigitHeight] = useState({});
  const digitRef = useRef();
  const positionStyle = {
    transform: `translate(0px, -${props.value * digitHeight}px)`,
  };

  const digitStyle = {
    fontSize: props.fontSize,
    height: props.fontSize,
    fontWeight: props.fontWeight,
    textAlign: props.align,
  };

  useLayoutEffect(() => {
    if (digitRef) {
      const styles = window.getComputedStyle(digitRef.current);
      setDigitHeight(parseFloat(styles.height));
    }
  }, []);

  return (
    <div className="l-digit l-flex--column-nowrap l-flex--center-center">
      <h2 className="h-digit" style={digitStyle} ref={digitRef}>
        0
      </h2>
      <div className="l-digit__list h-animation-config" style={positionStyle}>
        {Array.from(Array(10), (val, index) => {
          return (
            <h2 className="c-digit" style={digitStyle} key={index}>
              {index}
            </h2>
          );
        })}
      </div>
    </div>
  );
}

Digit.propTypes = {
  value: PropTypes.number.isRequired,
  align: PropTypes.string.isRequired,
  fontSize: PropTypes.number.isRequired,
  fontWeight: PropTypes.string.isRequired,
};
