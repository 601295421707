import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

export default function Tag(props) {
  const scale = props.scale ? props.scale : 1;

  const verticalPadding = 4 * scale;
  const horizontalPadding = 16 * scale;
  const fontSize = (props.fontSize ? props.fontSize : 11) * scale;
  let border;

  if (props.isOutline) {
    border = "1px solid #2a002a";
  } else {
    border = "none";
  }

  return (
    <div
      className="l-tag h-center"
      style={{
        padding: `${verticalPadding}px ${horizontalPadding}px`,
        border: border,
      }}
    >
      <h3
        className="c-tag__name"
        style={{
          fontSize: fontSize,
        }}
      >
        {props.name}
      </h3>
    </div>
  );
}

Tag.propTypes = {
  name: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  scale: PropTypes.number,
};
