/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

export default function Title(props) {
  return (
    <div className="l-title">
      <img src={props.iconUrl} className="c-title__icon"></img>
      <h1 className="c-title__name">{props.name}</h1>
    </div>
  );
}

Title.propTypes = {
  iconUrl: PropTypes.string,
  name: PropTypes.string,
};
