import React from "react";
import "./style.scss";

// import NanLogo from "./components/NanLogo/index";
import Menu from "./components/Menu/index";
import Footer from "./components/Footer/index";

export default function Frame() {
  return (
    <div id="l-frame">
      {/* <div id='l-frame__nan-logo' className='l-flex--row-nowrap'>
                <NanLogo />
            </div> */}
      <div id="l-frame__menu" className="l-flex--row-nowrap">
        <Menu />
      </div>
      <div id="l-frame__footer" className="l-flex--row-nowrap">
        <Footer />
      </div>
    </div>
  );
}
