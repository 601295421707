import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

import Letter from "./components/Letter";

export default function Text(props) {
  return (
    <div className="l-text">
      {props.value.map((sentence, index) => {
        return (
          <div key={index}>
            {sentence.split("").map((letter, index) => {
              return (
                <Letter
                  key={index}
                  value={letter}
                  fontSize={props.fontSize}
                  fontWeight={props.fontWeight}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

Text.propTypes = {
  value: PropTypes.array.isRequired,
  fontSize: PropTypes.number.isRequired,
  fontWeight: PropTypes.string.isRequired,
};
