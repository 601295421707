import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

export default function Project(props) {
  const beginDate = props.beginDate ? new Date(props.beginDate) : new Date();
  const endDate = props.endDate ? new Date(props.endDate) : new Date();

  return (
    <div className="l-project h-animation-config">
      <div className="c-project__carpet h-animation-config"></div>
      <picture className="l-project__thumbnail">
        <img
          className="c-project__thumbnail h-animation-config"
          src={props.imageUrl}
          alt="Thumbnail"
        />
      </picture>
      <article className="l-project__detail h-animation-config">
        {/* <img className='c-project__title-icon' src='/media/icons/project-name.svg' /> */}
        <h2 className="c-project__title h-animation-config">{props.name}</h2>
        <h3 className="c-project__date">
          {`${beginDate.toLocaleString("default", {
            month: "short",
          })} ${beginDate.getFullYear()} - ${endDate.toLocaleString("default", {
            month: "short",
          })} ${endDate.getFullYear()}`}
        </h3>
        <div className="l-project__link">
          <a
            className="l-project__link-icon h-center"
            href={props.link}
            target="_blank"
            without
            rel="noopener noreferrer"
          >
            <img
              className="c-project__code-icon"
              src="/media/icons/link.svg"
              alt="Application Link"
            />
          </a>
          {props.sourceUrl ? (
            <a
              className="l-project__link-icon h-center"
              href={props.sourceUrl}
              target="_blank"
              without
              rel="noopener noreferrer"
            >
              <img
                className="c-project__code-icon"
                src="/media/icons/code.svg"
                alt="Source Code Link"
              />
            </a>
          ) : (
            <span />
          )}
        </div>
      </article>
    </div>
  );
}

Project.propTypes = {
  name: PropTypes.string.isRequired,
  beginDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  sourceUrl: PropTypes.string,
};
