import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./style.scss";

const ANIMATION_LIST = [
  "appear-from-top",
  "appear-from-right",
  "appear-from-bottom",
  "appear-from-left",
];

function getAnimation() {
  return ANIMATION_LIST[Math.floor(Math.random() * ANIMATION_LIST.length)];
}

export default function Letter(props) {
  const [state, setState] = useState(getAnimation());
  const [delay, setDelay] = useState(Math.random() * 0.8);
  const onHover = (enter) => {
    if (enter) {
      setState("dis" + getAnimation());
    } else {
      setState(getAnimation());
    }
    setDelay(0);
  };

  useEffect(() => {
    setState(getAnimation());
    return () => {
      setState("dis" + getAnimation());
    };
  }, [props.value]);

  let display = "inline-block";
  if (props.value === " ") {
    display = "initial";
  }
  return (
    <span
      className="l-letter"
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
      style={{
        fontSize: props.fontSize,
        fontWeight: props.fontWeight,
        display,
      }}
    >
      <span
        className="c-letter h-animation-config"
        data-state={state}
        style={{
          display,
          fontSize: props.fontSize,
          fontWeight: props.fontWeight,
          animationDelay: `${delay}s`,
        }}
      >
        {props.value}
      </span>
    </span>
  );
}

Letter.propTypes = {
  value: PropTypes.string,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.string,
};
