/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./style.scss";

import Star from "./components/Star";

export default function Background({ maxState = 4, padding = 100, state = 0 }) {
  return (
    <div
      id="l-background"
      className="h-animation-config"
      style={{
        width: `calc(100vw + ${padding}px)`,
        transform: `translate(-${(padding / maxState) * state}px, 0)`,
      }}
    >
      <div id="l-background__star--1">
        <Star
          background="#5b86a3"
          layers={[
            {
              size: 30,
              opacity: 0.5,
            },
            {
              size: 200,
              opacity: 0.2,
            },
            {
              size: 500,
              opacity: 0.05,
            },
            {
              size: 1200,
              opacity: 0.01,
            },
          ]}
        />
      </div>
      <div id="l-background__star--2">
        <Star
          background="#5b86a3"
          layers={[
            {
              size: 70,
              opacity: 0.5,
            },
            {
              size: 400,
              opacity: 0.2,
            },
            {
              size: 1000,
              opacity: 0.05,
            },
            {
              size: 1700,
              opacity: 0.03,
            },
          ]}
        />
      </div>
      <img id="c-background__image" />
    </div>
  );
}
