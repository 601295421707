import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./style.scss";

import useWindowDimensions from "utils/Util";
import Number from "components/Number";

const NORMAL_FONT_SIZE = 14;

export default function SummaryTagSkill(props) {
  const { width } = useWindowDimensions();
  const [state, setState] = useState("empty");
  const [fontSize, setFontSize] = useState(NORMAL_FONT_SIZE);
  const [fillerStyle, setFillerStyle] = useState({
    transform: `transform(-100%, 0)`,
  });
  const [percentage, setPercentage] = useState(0);

  const handleTagOnHover = (enter) => {
    if (enter) {
      setState("mouse-enter");
      setFillerStyle({
        transform: `translate(-${100 - props.percentage}%, 0)`,
      });
      setPercentage(props.percentage);
    } else {
      setState("idle");
      setFillerStyle({
        transform: `translate(-100%, 0)`,
      });
      setPercentage(0);
    }
  };

  useEffect(() => {
    if (width < 700) {
      setFontSize(8);
    } else {
      setFontSize(NORMAL_FONT_SIZE);
    }
  }, [width]);

  const percentageFontSize = fontSize * (7 / 8);

  return (
    <div
      id="l-summary-tag-skill"
      className="h-animation-config"
      data-state={state}
      onMouseEnter={() => handleTagOnHover(true)}
      onMouseLeave={() => handleTagOnHover(false)}
    >
      <div
        id="c-summary-tag-skill__fill"
        className="h-animation-config"
        style={fillerStyle}
      ></div>
      <div id="l-summary-tag-skill__label" className="h-center">
        <h3
          id="c-summary-tag-skill__title"
          className="h-animation-config h-center"
          style={{ fontSize: fontSize }}
        >
          {props.name}
        </h3>
        <div
          id="c-summary-tag-skill__percentage"
          className="h-animation-config h-center"
          style={{ fontSize: percentageFontSize, fontWeight: "bold" }}
        >
          <Number
            value={percentage}
            fontSize={percentageFontSize}
            digitLength={2}
            fontWeight={"bold"}
          />
          %
        </div>
      </div>
    </div>
  );
}

SummaryTagSkill.propTypes = {
  name: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  fontSize: PropTypes.number,
};
