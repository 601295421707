import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

import Digit from "./components/Digit";

export default function Number(props) {
  const suffixNumber = props.value.toString().split("");
  const prefixLength = props.digitLength - suffixNumber.length;

  const number = [
    ...Array.from(Array(prefixLength), () => {
      return { value: 0, align: "center" };
    }),
    ...suffixNumber.map((digit) => {
      return { value: parseInt(digit), align: "center" };
    }),
  ];
  number[0].align = "right";
  number[number.length - 1].align = "left";

  return (
    <div className="l-number l-flex--row-nowrap l-flex--center-center">
      {number.map((digit, index) => {
        return (
          <div className="l-number__digit" key={index}>
            <Digit
              value={digit.value}
              align={digit.align}
              fontSize={props.fontSize}
              fontWeight={props.fontWeight}
            />
          </div>
        );
      })}
    </div>
  );
}

Number.propTypes = {
  value: PropTypes.number.isRequired,
  digitLength: PropTypes.number.isRequired,
  fontSize: PropTypes.number.isRequired,
  fontWeight: PropTypes.string.isRequired,
};
